import React from "react"
import {graphql, Link} from "gatsby"

import {formatDate, langFromPath} from "../i18n-config"
import {rhythm, scale} from "../utils/typography"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1>{post.frontmatter.title}</h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginTop: rhythm(-0.5),
            }}
          >
            {formatDate(post.frontmatter.date, langFromPath(location.pathname))}
          </p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
            <div
                style={{
                    display: "flex",
                    marginBottom: rhythm(2.5),
                }}
            >
                <div>
                    {langFromPath(location.pathname) === "fr" ? (
                        <span>
            Écrit par <strong>Samuel Bouchet</strong> à Nantes.
          </span>
                    ) : (
                        <span>
            Written by <strong>Samuel Bouchet</strong> who lives and works in
            Nantes.
          </span>
                    )}
                    <div>
                        <a href="https://twitter.com/lythom">
                            <span>@Lythom</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
      }
    }
  }
`
